import { 
  createAsyncThunk, 
  createSlice, 
  PayloadAction 
} from '@reduxjs/toolkit'
import { selectConfig } from '../global/globalSlice'
import {
  fetchSessionsByCategory,
  fetchSessionsByUserEmail,
  fetchSessionsAccessUsers,
  searchSession,
} from './sessionsApi'
import { RootState } from '../../redux-store/store'
import axios from 'axios'
import { ISessionsState } from './sessionTypes'
import { concatSessionCategories, sessionCategories } from '../../constants/sessionCategories'
import { DashboardLayout } from '../../constants/dashboardLayout'
import { ISession } from '../../../types/Session'
import { IAlert } from '../../../types/Alert'
import { SortBy } from '../../constants/sortBy'
import { concatValues, convertFilteredIndexes } from '../../shared/utils/helpers'
import { IStatusType } from '../../../types/StatusType'
import { convertSortByValue } from '../../orders/utils/sessions'
import { SortOrder } from '../../../types/ReactTable'

export const initialState: ISessionsState = {
  data: [],
  sessionsTotalCount: 0,
  fetchingSessions: false,
  searchResults: null,
  searchError: null,
  alertUser: null,
  isFetching: false,
  dashboardLayout: DashboardLayout.Rows,
  currentPage: 0,
  rowsPerPage: 15,
  sortBy: SortBy.CreatedDate,
  sortDesc: false,
  accessUsers: [],
  selectedAccessUser: null,
  openSearchRefineDrawer: false,
  selectedCategories: []
}

export interface ISessionSearchQuery {
  term: string;
  termType: IStatusType;
  page: number;
  rows: number;
  order: SortOrder;
  sortBy: SortBy;
}

export const searchSessionAsync = createAsyncThunk(
  'sessions/searchSession',
  async (query: ISessionSearchQuery, { 
    getState, 
    dispatch, 
    rejectWithValue, 
    signal 
  }) => {
    const config: any = selectConfig(getState() as any)
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    const {
      term,
      termType,
      page,
      rows,
      sortBy
    } = query
    dispatch(setRowsPerPage(rows))
    dispatch(setCurrentPage(page))

    const sortQuery = sortBy ? `&SortOrder=${sortBy}` : '' ;
    const queryString = `TermTypes=${termType.id}`
      + `&Terms=${term}`
      + `&Page=${page + 1}`
      + `&PageCount=${rows}`
      + sortQuery;
    try {
      const response = await searchSession(queryString, source.token, config)
      return response
    } catch (err: any) {
      return rejectWithValue('Error')
    }
  }
)

export const goFetchSessionAsync = createAsyncThunk(
  'sessions/goFetchSessionAsync',
  async (index: number, { getState, rejectWithValue  } : { getState: () => any , rejectWithValue: any}) => {
    const config: any = selectConfig(getState() as any)
    try {
      const sessionsLoaded: any = await selectSessions(getState() as any)
      const updateSessionsArray = JSON.parse(JSON.stringify(sessionsLoaded))
      const response = await fetchSessionsByCategory(
        'Page=' + (updateSessionsArray[index].page + 1) 
        + '&PageCount=10'
        + '&Statuses=' + updateSessionsArray[index].value,
        config)
      const items = [...((updateSessionsArray[index].data) ? updateSessionsArray[index].data : []), ...response.sessions]
      return {index:index, items:items, totalCount:response.totalCount}
    } catch (err: any) {
      return rejectWithValue({index:index,error:err})
    }
  }
)
export interface IFetchMergedSessionsParams {
  page: number;
  rows?: string | number;
  sortBy?: string;
  desc?: SortOrder;
  categoriesArr?: number[];
}

export const goFetchMergedSessionAsync = createAsyncThunk(
  'sessions/goFetchMergedSessionAsync',
  async (data: IFetchMergedSessionsParams, { getState, dispatch, rejectWithValue  } : { getState: () => any, dispatch: any, rejectWithValue: any}) => {
    const config: any = selectConfig(getState() as any)
    const {
      page,
      rows, 
      sortBy, 
      desc,
      categoriesArr
    } = data

    const concatCategories = categoriesArr
      ? concatValues(convertFilteredIndexes(categoriesArr, sessionCategories), 'value')
      : concatSessionCategories
    const sortOrder: SortBy = convertSortByValue(sortBy, desc)
    dispatch(setRowsPerPage(rows))
    dispatch(setCurrentPage(page))
    try {
      let items:any[] = []
      let totalCount = 0
      // Do not send a request to the BE if there are no categories selected
      if (concatCategories) {
        const response = await fetchSessionsByCategory(
          'Page=' + (Number(page) + 1)
          + '&PageCount=' + rows
          + '&Statuses=' + concatCategories
          + '&SortOrder=' + sortOrder,
          config)
        items = [...response.sessions]
        totalCount = response.totalCount
      }

      return {items:items, totalCount:totalCount}
    } catch (err: any) {
      return rejectWithValue({ 
        error: err,
        type: 'error'
      })
    }
  }
)

export const fetchSessionsAccessUsersAsync = createAsyncThunk(
  'sessions/users',
  async (_, { getState, rejectWithValue }) => {
    const config: any = selectConfig(getState() as any)
    try {
      const response = await fetchSessionsAccessUsers(config)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const fetchSessionsByUserEmailAsync = createAsyncThunk(
  'sessions/user-sessions',
  async (email: string | null, { getState, rejectWithValue }) => {
    const config: any = selectConfig(getState() as any)
    
    try {
      const response = await fetchSessionsByUserEmail(email, config)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }

  }
)

export const sessionsSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    setSessions: (state: ISessionsState, action: PayloadAction<ISession[] | null, string>) => {
      state.data = action.payload
    },
    clearSearchResults: (state: ISessionsState) => {
      state.searchResults = null
      state.searchError =  null
      state.fetchingSessions = false
    },
    setAlert: (state: ISessionsState, action: PayloadAction<IAlert | null>) => {
      state.fetchingSessions = false,
      state.isFetching = false,
      state.fetchingSessions = false,
      state.alertUser = action.payload
        ? action.payload.type
          ? { type: action.payload.type, text: action.payload.text } 
          : { type: 'info', text: action.payload }
        : null
    },
    setDashboardLayout: (state: ISessionsState, action: PayloadAction<DashboardLayout>) => {
      state.searchResults = []
      state.dashboardLayout = action.payload
    },
    setCurrentPage: (state: ISessionsState, action: PayloadAction<string | number | undefined>) => {
      state.currentPage = Number(action.payload)
    },
    setRowsPerPage: (state: ISessionsState, action: PayloadAction<string | number | undefined>) => {
      state.rowsPerPage = Number(action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      // SEARCH SESSION
      .addCase(searchSessionAsync.pending, (state: ISessionsState) => {
        state.fetchingSessions = true
        state.sessionsTotalCount = 0
      })
      .addCase(searchSessionAsync.fulfilled, (state: ISessionsState, action: PayloadAction<any>) => {
        state.fetchingSessions = false
        state.searchResults = (action.payload && action.payload.sessions && action.payload.sessions.length !== 0) 
          ? action.payload.sessions
          : []
        state.sessionsTotalCount = (action.payload
          ? action.payload.totalCount
          : 0) 
      })
      .addCase(searchSessionAsync.rejected, (state: ISessionsState, action: PayloadAction<any>) => {
        if(action.payload) {
          state.fetchingSessions = false
        }
      })
      // FETCH SESSION
      .addCase(goFetchSessionAsync.pending,(state: ISessionsState, action: PayloadAction<undefined, string, { arg: number; requestId: string; requestStatus: 'pending'; }, never>) => {
        const updateSessionsArray = JSON.parse(JSON.stringify(state.data))
        updateSessionsArray[action.meta.arg].isNextPageLoading = true
        state.data = updateSessionsArray
      })
      .addCase(goFetchSessionAsync.fulfilled, (state: ISessionsState, action: PayloadAction<any>) => {
        const index = action.payload.index
        const totalCount = action.payload.totalCount
        const items = action.payload.items
        const updateSessionsArray = JSON.parse(JSON.stringify(state.data))
        updateSessionsArray[index].data = items
        updateSessionsArray[index].error = null
        updateSessionsArray[index].page = (updateSessionsArray[index].page + 1)
        updateSessionsArray[index].loaded = true
        updateSessionsArray[index].hasNextPage = (items.length !== totalCount)
        updateSessionsArray[index].isNextPageLoading = false
        updateSessionsArray[index].totalCount = totalCount
        state.data = updateSessionsArray
      })
      .addCase(goFetchSessionAsync.rejected, (state: ISessionsState, action: PayloadAction<any>) => {
          const updateSessionsArray = JSON.parse(JSON.stringify(state.data))
          updateSessionsArray[action.payload.index].error = action.payload.error
          updateSessionsArray[action.payload.index].data = []
          updateSessionsArray[action.payload.index].loaded = true
          updateSessionsArray[action.payload.index].hasNextPage = true
          updateSessionsArray[action.payload.index].isNextPageLoading = false
          state.data = updateSessionsArray
      })
      // FETCH MERGED SESSIONS
      .addCase(goFetchMergedSessionAsync.pending,(state: ISessionsState) => {
        state.fetchingSessions = true
        state.alertUser = null
      })
      .addCase(goFetchMergedSessionAsync.fulfilled, (state: ISessionsState, action: PayloadAction<any, string, { arg: any; requestId: string; requestStatus: 'fulfilled'; }, never>) => {
        const totalCount = action.payload.totalCount
        state.data = action?.payload?.items
        state.sessionsTotalCount = totalCount
        state.fetchingSessions = false
      })
      .addCase(goFetchMergedSessionAsync.rejected, (state: ISessionsState, action: PayloadAction<any, string, { arg: any; requestId: string; requestStatus: 'rejected'; }, never>) => {
          state.fetchingSessions = false
          state.alertUser = action.payload?.error
            ? { 
              type: action.payload.type 
                ? action.payload.type 
                : 'error', 
              text: action.payload.text 
                ? action.payload.text 
                : action.payload.error
              } 
            : { type: 'error', text: 'Something went wrong. Please try again later.' }
      })
      .addCase(fetchSessionsAccessUsersAsync.pending, (state: ISessionsState) => {
        state.isFetching = true
      })
      .addCase(fetchSessionsAccessUsersAsync.fulfilled, (state: ISessionsState, action: PayloadAction<any>) => {
        state.isFetching = false,
        state.accessUsers = action.payload.users
      })
      .addCase(fetchSessionsAccessUsersAsync.rejected, (state: ISessionsState) => {
        state.isFetching = false
      })
      .addCase(fetchSessionsByUserEmailAsync.pending, (state: ISessionsState) => {
        state.isFetching = true
      })
      .addCase(fetchSessionsByUserEmailAsync.fulfilled, (state: ISessionsState, action: PayloadAction<any>) => {
        state.isFetching = false,
        state.data = action.payload.sessions
        state.selectedAccessUser = action.payload.userEmail
      })
      .addCase(fetchSessionsByUserEmailAsync.rejected, (state: ISessionsState) => {
        state.isFetching = false
      })
  }
})

export const { 
  setSessions, 
  clearSearchResults,
  setAlert,
  setDashboardLayout,
  setCurrentPage,
  setRowsPerPage
} = sessionsSlice.actions;

export const selectSessions = (state: RootState) => state.sessions.data;

export default sessionsSlice.reducer
