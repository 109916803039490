import moment from 'moment'
import { IOrderTransactionEvent } from '../../../types/PartyEvents'
import { IPhoneNumber } from '../../../types/PhoneNumber'
import { PackageStatusType } from '../../enums'

/**
 * formats UTC date and time into format param
 * @param data Date in UTC
 * @param format Date and time format. See https://momentjs.com/docs/#/displaying/
 */
 export const formatDate = (data?: string | moment.Moment | Date | number | null, format?: any, convertUtc = true):string => {
  if (data && format) {
    let updatedDate = ''
    if (convertUtc)
      updatedDate = moment.utc(data).local().format(format)
    else
      updatedDate = moment(data).format(format)

    return updatedDate
  } else {
    return 'N/A'
  }
}

/**
 * 
 * @param obj phone number object (see IPhoneNumber)
 * @returns common phone number format and country code, e.g. '1 (512) 555-5555'
 */
 export const phoneObjToString = (obj: IPhoneNumber):string => {
  let phoneNumber = ''
  phoneNumber += (obj.CountryCode && obj.CountryCode.length > 0) ? obj.CountryCode : ''
  phoneNumber += (obj.AreaCode && obj.AreaCode.length > 0) ? ` (${obj.AreaCode})` : ''
  phoneNumber += (obj.Number && obj.Number.length > 0) ? ` ${obj.Number.substring(0, 3) + '-' + obj.Number.substring(3, 8)}` : ''
  return phoneNumber
}


/**
 * 
 * @param phone string of 10 numbers
 * @returns string formatted phone (xxx) xxx-xxxx
 */
export const normalizePhoneString = (phone: string) => {
  let phoneNumber = ''
  if (phone && phone.length) {
    const onlyNums = phone.replace(/[^\d]/g, '') // only allows 0-9
    const areaCodeString = onlyNums.substring(0, 3)
    const firstThree = onlyNums.substring(3, 6)
    const lastFour = onlyNums.substring(6, 10)
    phoneNumber += `(${areaCodeString}) ${firstThree}-${lastFour}`
    return phoneNumber
  }
  return phone
}

/**
 * 
 * @param value unformatted phone number, e.g. 5125555555
 * @param previousValue unformatted phone number, e.g. 5125555555
 * @returns phone number format, e.g. ' (512) 555-5555' - space in front for international code
 * 
 */
export const normalizePhoneInput = (value: string): string => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '')

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less than four digits
  // this is to avoid weird behavior that occurs if you  format the area code too early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`
}

/**
 * 
 * @param eventsArray array of history of events
 * @returns eventsArray sorted chronological order by EventDateTime
 */
export const sortEventsByDateTime = (eventsArray: IOrderTransactionEvent[]): IOrderTransactionEvent[] => {
  const events: IOrderTransactionEvent[] = eventsArray.slice()
  events.sort((a, b) => {
    const aDateTime = moment.utc(a.EventDateTime).local().format()
    const bDateTime = moment.utc(b.EventDateTime).local().format()
    if (aDateTime < bDateTime) {
      return -1
    }
    if (aDateTime > bDateTime) {
      return 1
    }
    return 0
  })
  return events
}


/**
 * 
 * @param {string} fullName
 * @returns {string} initials from fullName
 */
export const getInitials = (fullName: string) => {
  const removeSuffix = fullName.trim().split(',')
  const allNames = removeSuffix[0].trim().split(' ')
  const initials = allNames.reduce((acc, curr, index) => {
    if(index === 0 || index === allNames.length - 1){
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return acc
  }, '')
  return initials
}

/**
 * 
 * @param {string} fullName 
 * @returns {string} first name from fullName
 */
export const getFirstName = (fullName: string) => {
  const names = fullName.split(' ')
  return names[0]
}

/**
 * 
 * @param {array} arr array of objects
 * @param {string} prop property name
 * @returns {array} sorted by provided property name
 */
export const sortByProperty = (arr: any[], prop: string) => {
  return arr.sort (
    function (a:any, b:any) {
      return a[prop].localeCompare(b[prop], undefined, {sensitivity: 'base'});
    }
)}

/**
 * 
 * @param {number} index index of object
 * @returns {object} adding correct props
 */

export const a11yProps: any = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}
/**
 * 
 * @param {string} word string of text to sentence case
 * @returns string
 */
export const titleCase = (word?: string) => {
  const newCasing: string = word && word.length > 1 
    ? word.split(' ')
      .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(' ')
    : ''
  return newCasing
}
/**
 * 
 * @param {string} disclosureStatus sessionCategories values
 * @returns {string}
 */
export const disclosureDisplayText = (disclosureStatus: string) => {
  switch (disclosureStatus) {
    case 'adverse_action':
      return 'adverse action'
    case 'disclosure': 
      return 'initial disclosure'
    case 'preclosing':
      return 'pre closing'
    case 'predisclosure':
    case 'pre_disclosure':
      return 'pre disclosure'
    case 'redisclosure':
      return 're disclosure'
    default:
      return disclosureStatus
  }
}
/**
 * 
 * @param {Array<any>} array list of values
 * @returns {Array<number>} array of numbers
 */
export const resetCheckboxes = (array?: any[]):number[] => {
  const resetArr: number[] = []
  if (array && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      resetArr.push(i)
    }
  } 
  return resetArr
}
/**
 * 
 * @returns {Array<string>} array of package statuses
 */
export const packageStatusesArr = ():any[] => {
  const statuses = []
  for (const [key] of Object.entries(PackageStatusType)) {
    statuses.push(key)
  }
  return statuses
}

export const convertFilteredIndexes = (indexes: number[], filterValues: any[]): any[] => {
  const newArr: any[] = []
  indexes.forEach((index:number) => newArr.push(filterValues[index]))
  return newArr
}
export const concatValues = (array: any[], propName?: string):string => {
  const concat: string[] = []
  array.map(e => concat.push(propName ? e[propName].toLowerCase() : e.toLowerCase()))
  return concat.join()
}

export const transformPackageStatusText = (packageStatus: string) => {
  switch (packageStatus) {
    case PackageStatusType.ReadyForTagging:
      return 'Ready For Tagging'
    case PackageStatusType.InProgress:
      return 'In Progress'
    case PackageStatusType.Cancelled:
      return 'Canceled'
    default:
      return packageStatus

  }
}
/**
 * 
 * @description Consumes an object of values example: {exampleProp:'value', exampleArray:['item1','item2','item3'] }
 * @param {Record<string,unknown>} params is a url object
 * @returns Single string to be used in url params example: 'exampleProp=value&exampleArray=item1&exampleArray=item2&exampleArray=item3'
 */
export const parseParams = (params:any) => {
  const keys = Object.keys(params);
  let options = '';

  keys.forEach((key) => {
    const isParamTypeObject = typeof params[key] === 'object';
    const isParamTypeArray = isParamTypeObject && (params[key].length >= 0);

    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`;
    }

    if (isParamTypeObject && isParamTypeArray) {      
      params[key].forEach((element:any) => {
        options += `${key}=${element}&`;
      });
    }
  });

  return options ? options.slice(0, -1) : options;
};

/**
 * truncates the start of a given string to a specified length and prefixes the truncated string with an ellipsis.
 * @param param
 * @param maxLength
 */
export const prefixEllipsis = (param: string, maxLength: number): string => {
  return param.length > maxLength ? '...' + param.substring(param.length - maxLength) : param
}

/**
 * transformJsonKeyCasing
 * @param jsonObj Object
 * @param casing pascal or camelCase
 * @returns object
 */
export const transformJsonKeyCasing = (
  jsonObj: Record<string, any>, 
  casing: 'pascal' | 'camel'
) => {
  const string = JSON.stringify(jsonObj, function (_, value) {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const replacement: any = {};
      for (const k in value) {
        if (Object.hasOwnProperty.call(value, k)) {
          
          if (casing === 'pascal') {
            replacement[k && k.charAt(0).toUpperCase() + k.substring(1)] = value[k];
          } else {
            replacement[k && k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          }
        }
      }
      
      return replacement;
    }
    return value;
  });
  return JSON.parse(string) 
}